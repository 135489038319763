<template>
    <div id="ws-banner">
      <!--轮播图-->
      <div class="big_view">
        <ul class="product_list" ref="product_list">
          <li v-for="(item,index) in carousels" :key="item.title + index + Date.now()" class="produce_box" :class="{show:index === 0}" :style="index !== 0 && {opacity: 0}">
            <!--<img :src="item.src">-->
            <router-link :to="item.link" >
              <i class="bannerBg" :style="{backgroundImage: 'url('+item.mImg +')'}"></i>
            </router-link>
            <div class="text-box">
              <h2 class="txt_info">
                <p class="txt_info_title">{{item.title}}</p>
                <p class="txt_info_des">{{item.des}}</p>
              </h2>
              <router-link :to="item.link" class="viewDetail" target="_blank" v-show="item.button !== false">查看详情</router-link>
            </div>
          </li>
        </ul>
        <div class="product_btns">
          <div class="btns_area" ref="btns_area">
            <a v-for="(item,index) in carousels" href="javascript:;" :key="item.title + index + Date.now()"><i></i></a>
            <i class="btns_active_line"></i>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import {promiseRequestConfig, compareObj} from '../../assets/utils/commonUtils'
  import {initBanner} from './controls/banner'
  export default {
      name: 'Banner',
      /*props: ['carousels'],*/
      data(){
        return {
          requestPromise: null, //请求数据的promise对象
          carousels: [
            {
              "img"   : "homepage-banner01.png",
              "des"   : "节点全球覆盖，边缘与中心全面融合协作",
              "title" : "边缘云计算",
              "link"  : "/solution/EdgeComputing"
            },
            {
              "img"   : "homepage-banner02.png",
              "des"   : "超强计算能力，有效释放高密度计算压力",
              "title" : "网宿云GPU云服务器",
              "link"  : "/product/GPUCloudServer"
            },
            {
              "img"   : "homepage-banner03.png",
              "des"   : "网宿云主机超过50%的可用区已经获得可信云五星+最高级别认证",
              "title" : "荣获可信云首批AAA级云服务企业信用评级",
              "link"  : "/newsDetail/10017"
            },
            {
              "img"   : "homepage-banner04.png",
              "des"   : "按需分配 弹性扩展 轻量可靠",
              "title" : "按需分配 弹性扩展 轻量可靠",
              "link"  : "/product/AutoScaling"
            },
            {
              "img"   : "homepage-banner05.png",
              "des"   : "建立纵深防御体系，保障企业云上安全",
              "title" : "网宿云安全",
              "link"  : "/solution/GeneralSecurity"
            },
            {
              "img"   : "homepage-banner06.png",
              "des"   : "动态扩展，灵活转换",
              "title" : "网宿云存储",
              "link"  : "/product/ObjectStorage"
            }
          ]
        }
      },
      created(){
        //请求banner数据
        this.requestPromise = new Promise(resolve => {
          //先取缓存的，没有再请求
          !window.localStorage.getItem('ws_banner') ? this.getBannerData(resolve, false): this.getBannerData(resolve, true);
        });
      },
      mounted() {
       this.requestPromise.then(()=>{
          this.initFunction();
        })
      },
      methods:{
        getBannerData(resolve, hasStorage){
          if(hasStorage) {
            let ws_banner = JSON.parse(window.localStorage.getItem('ws_banner'));
            this.carousels = ws_banner;
            //另外发起个请求新数据做比较
            this.requestBanner((carousels)=>{
              if(!compareObj(carousels, ws_banner)){
                window.localStorage.setItem('ws_banner', JSON.stringify(carousels));
                this.carousels = carousels;
                this.$nextTick(()=>{
                  this.initFunction();
                })
              }
            });
            this.$nextTick(()=>{
              resolve();
            })
          }else{
            this.requestBanner((carousels)=>{
              window.localStorage.setItem('ws_banner', JSON.stringify(carousels));
              this.carousels = carousels;
              this.$nextTick(()=>{
                resolve();
              })
            });
          }
        },
        requestBanner(callback){
          promiseRequestConfig('config/home', 'banner.json', 'banner').then((carousels) => {
            callback && callback(carousels);
          }).catch(() => {
            console.log('请求导航数据出错');
            this.$router.push({
              path: '/error'
            })
          })
        },
        //初始化功能
        initFunction(){
          this.$nextTick(()=> {
            initBanner(
              this.$refs.product_list.querySelectorAll('li.produce_box'),
              this.$refs.btns_area.querySelectorAll('a'),
              this.$refs.btns_area.querySelector('.btns_active_line')
            );
          });
        }
      }
  }
</script>

<style lang="scss" scoped>
  @import './style/banner.scss';
</style>
