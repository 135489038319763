import $ from 'jquery'

let initBanner = (function(){
  let products_box = null;
  let curIndex = 0, //当前选中的序号，默认0
      len = 0,
      cur_zIndex = 1, //当前z_index，每次切换后z_index++，默认1
      timer = null;
  return function(products, btns, line) {
    products_box = products;
    curIndex = 0, //当前选中的序号，默认0
    len = products_box.length,
    cur_zIndex = 1, //当前z_index，每次切换后z_index++，默认1
    setTimeout(()=>{ //进入页面后延迟一会再开始轮播
      clearInterval(timer);
      timer = setInterval(()=>{
        bannerChange((curIndex+1) % len);
      },3500);
    }, 3500);
    (products_box[curIndex].style.zIndex = cur_zIndex);
    //绑定按钮触屏事件
    Array.from(btns).forEach((item, index) => {
      //第一个轮播图默认初始化显示，并把line定位到对应位置
      index === curIndex && (products_box[index].style.zIndex = cur_zIndex, line.style.left = item.offsetLeft + 'px');
      item.addEventListener('click',function(){
        bannerChange(index);
      })
    });
    let bannerChange = function(index){
      if(index !== curIndex){
        //改变banner按钮的线条位置，随鼠标变化
        line.style.left = btns[index].offsetLeft + 'px';
        //改变banner轮播显示
        products_box[curIndex].style.zIndex = cur_zIndex;
        products_box[curIndex].classList.remove('show');
        curIndex = index;
        products_box[curIndex].classList.add('show');
        $(products_box[curIndex]).css({opacity:0,zIndex:++cur_zIndex}).stop(true,true).animate({opacity:1},400);
      }
    }
  }
}());
export {
  initBanner
}

