<template>
  <div id="ws_home">
    <!-- 头部 -->
    <!--<Header></Header>-->
    <!--<div class="ws_pageContent">-->
      <!-- 轮播图 -->
      <Banner></Banner>
      <!-- 托管云、弹性伸缩、安全、快速上手 4块 -->
      <div class="quick_entrance">
        <ul>
          <li class="quick_box quick_box_2">
            <div class="quick_box_container">
              <router-link to="/solution/ManagedCloud">
                <img src="../../../static/regular/img/icon/home/home_managed_cloud.svg" />
                <p>托管云</p>
              </router-link>
            </div>
          </li>
          <li class="quick_line"></li>
          <li class="quick_box quick_box_1">
            <div class="quick_box_container">
              <router-link to="/product/AutoScaling">
                <img src="../../../static/regular/img/icon/home/home_expansion.svg" />
                <p>弹性伸缩</p>
              </router-link>
            </div>
          </li>
          <li class="quick_line"></li>
          <li class="quick_box quick_box_1">
            <div class="quick_box_container">
              <router-link to="/solution/GeneralSecurity">
                <img src="../../../static/regular/img/icon/home/home_security.svg" />
                <p>安全</p>
              </router-link>
            </div>
          </li>
          <li class="quick_line"></li>
          <li class="quick_box quick_box_2">
            <div class="quick_box_container">
              <router-link to="/help/details/21/3810">
                <img src="../../../static/regular/img/icon/home/home_quick.svg" />
                <p>快速上手</p>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- 享受安全极速的云服务 -->
      <div class="ws_product_tab">
        <div class="ws_serve_head">
          <h1>享受安全极速的云服务</h1>
          <p>网宿深耕于新产品、新技术的研发、 为客户提供更优质的云产品</p>
        </div>
        <div class="home_product_wrap">
          <div class="home_product_tab">
            <ul ref="homeProduct">
              <li class="home_product_nav" v-for="item in (product?product.children:[])">
                <a href="javascript:;" class="home_product_nav_info">
                  <div class="wsc_product_icon">
                    <i class="icon_homepage iconfont default" :style="{backgroundImage: 'url('+configurablePath+'/img/icon/home/'+item.default_icon +')'}"></i>
                    <i class="icon_homepage iconfont active" :style="{backgroundImage: 'url('+configurablePath+'/img/icon/home/'+item.active_icon +')'}"></i>
                  </div>
                  <p class="tab_tit">{{item.name}}</p>
                  <em class="dropdownSwitch icon-angle-down"></em>
                </a>
                <div class="home_product_detail">
                  <div class="home_product_detail_container">
                    <div class="home_product_item" v-for="detail in item.children">
                      <router-link :to="detail.link">
                        <h3>{{detail.name}}</h3>
                      </router-link>
                      <p :title="detail.des">{{detail.des}}</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 专业解决方案 -->
      <div class="ws_product">
        <div class="ws_serve_head">
          <h1>为不同行业提供专业的解决方案</h1>
          <p>网宿致力于为不同行业提供专业化的解决方案</p>
        </div>
        <div class="wsc_tab">
          <div class="nav_tabs">
            <ul ref="homeSolute">
              <template v-for="solute in soluteData">
                <li class="home_solute_nav" v-for="item in solute.children">
                  <a href="javascript:;" class="home_solute_nav_info">
                    <p class="tab_tit">{{item.title}}</p>
                    <em class="dropdownSwitch icon-angle-down"></em>
                  </a>
                  <div class="home_solute_detail">
                    <div class="home_solute_detail_container">
                      <p class="des">{{item.des}}</p>
                      <router-link :to="item.more" class="more">了解更多 ></router-link>
                      <div class="solute_log">
                        <div class="solute_log_item" v-for="logo in item.m_logo">
                          <img :src="require('../../../static/regular/img/brand/home/'+logo)" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- 超大规模数据中心 -->
      <div class="ws_node">
        <div class="ws_serve_head">
          <h1>超大规模数据中心 <br/>遍布全球</h1>
          <p>全球部署超过 <span>1500+</span> 个节点</p>
        </div>
        <i class="node_map">
        </i>
        <ul class="node_list">
          <li>
            <h3><span>10+</span><img src="../../../static/regular/img/about/Aboutws_datacenter.svg" /></h3>
            <p>全球清洗中心</p>
          </li>
          <li>
            <h3><span>70+</span><img src="../../../static/regular/img/about/Aboutws_global.svg" /></h3>
            <p>覆盖国际及地区</p>
          </li>
          <li>
            <h3><span>100+</span><img src="../../../static/regular/img/about/Aboutws_inland.svg" /></h3>
            <p>合作海外运营商</p>
          </li>
          <li>
            <h3><span>15+</span><img src="../../../static/regular/img/about/Aboutws_overseasnode.svg" /></h3>
            <p>可承载DDoS攻击15+Tbps</p>
          </li>
        </ul>
      </div>
      <!-- 提供安全合规的云计算服务 -->
      <div class="ws_authentication">
        <div class="ws_serve_head">
          <h1>安全合规的云计算服务</h1>
          <p>网宿云可靠的企业级云服务专家</p>
        </div>
        <ul class="authentication_contents">
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/DJCP.png" alt="" width="70" height="70">
              <p>公安部等级保护评测</p>
            </router-link>
          </li>
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/ISCCC.png" alt="" width="70" height="70">
              <p>IT产品信息安全认证</p>
            </router-link>
          </li>
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/ISO27001.png" alt="" width="70" height="70">
              <p>信息安全管理体系国际认证</p>
            </router-link>
          </li>
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/trusted_cloud_services.png" alt="" width="70" height="70">
              <p>可信云服务认证</p>
            </router-link>
          </li>
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/ITSS.png" alt="" width="70" height="70">
              <p>信息技术服务标准评测</p>
            </router-link>
          </li>
          <li>
            <router-link to="/authentication">
              <img src="../../../static/regular/img/icon/home/PCI.png" alt="" width="70" height="70">
              <p>PCI DSS认证</p>
            </router-link>
          </li>
        </ul>
      </div>
    <!--</div>-->
    <!-- 底部 -->
    <!--<Footer></Footer>-->
  </div>
</template>

<script>
  import {promiseRequestNavConfig} from '../../assets/utils/commonUtils'
  import {initSpreadFunction} from '../../assets/utils/commonUtils'
  import Banner from '../../components/banner/banner'

  /* 公共导航栏数据 */
  //const navData = Vue.prototype.cache.nav_data;
  /* 解决方案区域数据 */
  import soluteData from '../../../static/regular/config/home/solute'
  /* banner数据 */
  //const bannerData = Vue.prototype.cache.banner;

  export default {
    name: 'Home',
    data() {
      return {
        requestPromise: null, //请求数据的promise对象
        product: null, //享受安全极速的云服务数据
        productNavIndex: 0, //享受安全极速的云服务当前选中
        soluteData, //解决方案数据
      }
    },
    components: {
      Banner
    },
    created() {
      this.requestPromise = new Promise(resolve => {
        promiseRequestNavConfig().then(navData => {
          //初始化产品数据（享受安全极速的云服务）
          this.product = navData.find(item => {
            return item.key === 'product';
          })
          this.$nextTick(() => {
            resolve();
          })
        }).catch(() => {
          console.log('请求导航数据出错');
          this.$router.push({
            path: '/error'
          })
        })
      })
    },
    mounted() {
      this.requestPromise.then(() => {
        //初始化产品（享受安全极速的云服务）操作

        initSpreadFunction({
          observers: this.$refs.homeProduct.querySelectorAll('.home_product_nav'),
          responsersSelector: '.home_product_detail',
          activeClass: 'active',
          isLinkageClose: false
        });
        //初始化解决方案（为不同行业提供专业的解决方案）操作
        initSpreadFunction({
          observers: this.$refs.homeSolute.querySelectorAll('.home_solute_nav'),
          responsersSelector: '.home_solute_detail',
          activeClass: 'active',
          isLinkageClose: false
        });
      });
    }
  }
</script>

<style lang="scss" scoped>
  @import "./styles/home.scss";
</style>
