import Vue from 'vue'
//获取元素到body顶部的距离
let getOffset = function(dom){
    let o = {left:0 ,top: 0};
    while(dom !== document.body){
        o.top  += dom.offsetTop;
        o.left += dom.offsetLeft;
        dom = dom.offsetParent;
    }
    return o;
};
//滚动效果
// 参数：1、滚动到的DOM节点 2、滚动速度(默认15)
let scrollTo = (function(){
    let timer = null;
    return function(ele, speed=15){
        //从当前位置滚动到指定位置
        let scrollTopFrom = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop,
            scrollTopTo = getOffset(ele).top;

        let d_value = (scrollTopTo - scrollTopFrom)/speed;//每次滚动的偏移值
        timer = setInterval(function(){
            scrollTopFrom += d_value;
            scrollTopFrom = Math[d_value>0 ? 'min': 'max'](scrollTopFrom,scrollTopTo);
            document.documentElement.scrollTop = scrollTopFrom;
            window.scrollTo(0, scrollTopFrom);
            (scrollTopFrom === scrollTopTo) && clearInterval(timer);
        },1000/60);
    }
})();
//获取元素属性值
let getComputed = (function(){
    return function(dom, attr){
        if( dom.currentStyle){
            return dom.currentStyle[attr];
        }else{
            return getComputedStyle(dom)[attr];
        }
    }
}());
//回到浏览器顶部
let refreshPosition = (function(){
    return function(x = 0, y = 0){
        document.documentElement.scrollTop = y;
        window.scrollTo(x,y);
    }
}());

//校验IE版本
let validBrowser = (function(){
    return function(){
        //取得浏览器的userAgent字符串
        var userAgent = navigator.userAgent;
        //判断是否IE浏览器
        var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
        if (isIE) {
            var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
            reIE.test(userAgent);
            var fIEVersion = parseFloat(RegExp["$1"]);
            if (fIEVersion < 10) {
                return true;
            }else{
                return false;
            }
        } else {
            return false;
        }
    }
}());
//promise请求json配置
let promiseRequestConfig = (function(){
    return function (path, fileName,cacheName, isCustom) { //例  path: config/common   fileName: nav_data.json  cacheName: nav_data  isCustom:true（是否自定义前缀路径)
        return new Promise((resolve, reject)=>{
            if(Vue.prototype.cache[cacheName]){
                //如果已有数据，不需要再去请求
                resolve(Vue.prototype.cache[cacheName]);
                return;
            }
            Vue.prototype.$axios.get(isCustom ? `${path}/${fileName}`:`${Vue.prototype.configurablePath}/${path}/${fileName}?t=${Date.now()}`)
                .then((res)=>{
                    Vue.prototype.cache[cacheName] = res.data;
                    resolve(Vue.prototype.cache[cacheName]);
                }).catch(()=>{
                reject();
            })
        })
    }
}());
//封装请求导航栏的nav配置（目前就产品页可配置）
let promiseRequestNavConfig = (function(){
    let navPromiseCache = {};//导航promise请求缓存（当header和home同时去请求时，只请求一次）
    return function(){
        let navPromise = navPromiseCache['nav'];
        !navPromise && (
            navPromise = new Promise((resolve, reject) => {
                Promise.all([
                    promiseRequestConfig('config/common', 'nav_product.json', 'nav_product')
                    /*promiseRequestConfig('config/common', 'nav_managedCloud.json', 'nav_managedCloud'),
                    promiseRequestConfig('config/common', 'nav_solution.json', 'nav_solution'),
                    promiseRequestConfig('config/common', 'nav_steady.json', 'nav_steady')*/
                ]).then(()=>{
                    //合并nav_data数据
                    const manageCloudData = require('../../../static/regular/config/common/nav_managedCloud'),
                        EdgeComputingData = require('../../../static/regular/config/common/nav_edgeComputing'),
                        solutionData = require('../../../static/regular/config/common/nav_solution'),
                        steadyData = require('../../../static/regular/config/common/nav_steady');
                    /*Vue.prototype.cache.nav_data = [
                      ...[Vue.prototype.cache.nav_product],
                      ...[Vue.prototype.cache.nav_managedCloud],
                      ...[Vue.prototype.cache.nav_solution],
                      ...Vue.prototype.cache.nav_steady
                    ];*/
                    Vue.prototype.cache.nav_data = [
                        ...[Vue.prototype.cache.nav_product],
                        ...[manageCloudData],
                        ...[EdgeComputingData],
                        ...[solutionData],
                        ...steadyData
                    ];
                    resolve(Vue.prototype.cache.nav_data);
                }).catch(()=>{
                    reject();
                });
            }),
                navPromiseCache['nav'] = navPromise
        );
        return navPromise;
    }
}());
//Cookie get set
let Cookie = {
    SET : function(json, time, path) {
        let date = new Date(Date.now() + (time ? time : 0) * 24 * 60 * 60 * 1000);
        for (let key in json){
            document.cookie = (key + '=' + json[key] + ';')
                + (time ? ('expires=' + date.toUTCString() + ';') : '')
                + (path ? ('path=' + path + ';'): 'path=/');
        }
    },
    GET : function(key){
        return document.cookie.match(new RegExp("(^|\\s)" + key + "=([^;]*)(;|$)"))[2];
    }
}

//封装绑定滚动事件
let mouseWheel = (function(){
    return function(ele, eFn){
        function fn(e){
            e = e || window.event;
            //让滚动方向统一 （向上滚动>0、向下滚动<0）
            let direction = e.wheelDelta / 120 || -e.detail / 3;
            eFn.call(ele, e, direction);
        }
        //判断是否是火狐浏览器
        let type = document.onmousewheel === null? 'mousewheel' : 'DOMMouseScroll';
        //判断是否是IE8
        window.addEventListener ? ele.addEventListener(type, fn)
            : ele.attachEvent('on' + type, fn);
    }
}());

//封装展开明细功能（适用展开内容不定高）
let initSpreadFunction = (function(){
    /*  注：需要先将响应容器height都设为0
     *  参数说明
     *  direction 方向，默认垂直展开 column row
     *  observers 触发容器
     *  responsers 对应响应容器（与observers序号各自对应）
     *  responsersSelector 对应响应容器选择器（用于响应容器是observer内部元素）
     *  responsersOther (可取next / previous，用于响应容器是observer前一个/后一个兄弟元素)
     *  activeClass 点击后切换的class名
     *  isLinkageClose 新的展开是否联动关闭其他展开项
     *  asyncBeforeOpenCallback 异步点击展开前的回调，接收resolve
     *  openCallback 同步点击展开的回调
     *  asyncAfterOpenCallback 异步点击展开后的回调
     *  asyncBeforeCloseCallback 异步点击收起前的回调，接收resolve
     *  closeCallback 同步点击收起的回调
     *  asyncAfterCloseCallback 异步点击收起后的回调
     *  animationTime 动画时长，默认500ms
     */
    return function(params){
        let curShowIndex = undefined;
        let {
            direction = 'column',
            observers = [],
            responsers = [],
            responsersSelector,
            responsersOther,
            activeClass = '_active',
            isLinkageClose = true,
            asyncBeforeOpenCallback,
            openCallback,
            asyncAfterOpenCallback,
            asyncBeforeCloseCallback,
            closeCallback,
            asyncAfterCloseCallback,
            animationTime = 500
        } = params;
        let _attr = {column: 'height', row: 'width'},
            _attrOffset = {column: 'scrollHeight', row: 'scrollWidth'};
        //点击后的动作
        let clickAction = function(resolve){
            if(!this.switch)
                return;
            this.switch = false;
            this.classList.toggle(activeClass);
            let detail = (responsers.length && responsers[this.index]) ||
                (responsersSelector && this.querySelector(responsersSelector)) ||
                (responsersOther === 'next' ? this.nextElementSibling : this.previousElementSibling);
            if(this.classList.contains(activeClass)){
                //关闭其他的展开项
                if(isLinkageClose) {
                    let _otherObserver = null, _otherResponse = null;

                    curShowIndex !== undefined
                    && curShowIndex !== this.index
                    && (
                        _otherObserver = observers[curShowIndex],
                            _otherObserver.classList.remove(activeClass),
                            _otherResponse = (responsers.length && responsers[curShowIndex]) ||
                                (responsersSelector && _otherObserver.querySelector(responsersSelector)) ||
                                (responsersOther === 'next' ? _otherObserver.nextElementSibling : _otherObserver.previousElementSibling),
                            _otherResponse.style[_attr[direction]] = ''
                    );
                    curShowIndex = this.index;
                }
                //计算高度后展开（可以展开的才展开）
                //let maxH = detail.children[0][_attrOffset[direction]];//展开高度为item_detail_container的高度
                let maxH = detail[_attrOffset[direction]];
                detail.style[_attr[direction]] = maxH + 'px';
                setTimeout(() => {detail.style[_attr[direction]] = 'auto';},animationTime);
                //回调
                openCallback && openCallback();
            }else{
                detail && (detail.style[_attr[direction]] = detail[_attrOffset[direction]] + 'px');
                setTimeout(()=>{detail.style[_attr[direction]] = 0},10);
                //回调
                closeCallback && closeCallback();
            }
            setTimeout(()=>{resolve && resolve()}, animationTime);
        }
        Array.from(observers).forEach((item, index) => {
            item.index = index;
            item.switch = true;//防抖
            item.onclick = function(){
                //this.classList.toggle(activeClass);//切换状态
                let curActive = !this.classList.contains(activeClass);//当前是要打开还是关闭
                new Promise(resolve =>{
                    curActive && (asyncBeforeOpenCallback && (!!asyncBeforeOpenCallback(resolve) || true) || resolve());//打开
                    !curActive && (asyncBeforeCloseCallback && (!!asyncBeforeCloseCallback(resolve) || true) || resolve());//关闭
                }).then(() => {
                    return new Promise(resolve => {
                        clickAction.call(this, resolve);
                    });
                }).then(() => {
                    this.switch = true;
                    curActive && asyncAfterOpenCallback && asyncAfterOpenCallback();//打开
                    !curActive && asyncAfterCloseCallback && asyncAfterCloseCallback();//关闭
                });
            };
        });
        //关闭全部项
        let closeAll = function(){
            //级联各个项只需要关闭当前项（只会展开1项）
            if(isLinkageClose){
                curShowIndex!==undefined && observers[curShowIndex].click();
            }else{
                //不是级联需遍历全部关闭
                Array.from(observers).forEach(item => {
                    item.classList.contains(activeClass) && item.click();
                });
            }
            curShowIndex = undefined;
        }
        return {
            closeAll,
            observers
        }
    }
}());

//数据形成树结构
let buildTree = (function(){
    let sortTree = function(tree, orderNo){
        const [orderNo1, orderNo2] = orderNo;
        tree.sort((a, b) => {
            if(a[orderNo1] === b[orderNo1]) {
                return a[orderNo2] - b[orderNo2];
            }
            if(a[orderNo1] === null) {
                return 1;
            }
            if(b[orderNo1] === null) {
                return -1;
            }
            return a[orderNo1] - b[orderNo1];
        })

        tree.forEach(item => {
            item.children && sortTree(item.children, orderNo);
        })
    }
    return function(datas, id, parent, orderNo){
        let temps = {},//处理为 1: {id:1, parent:0}的形式
            tree = [];//返回的树结构
        //整理temps
        datas.forEach(item => {
            temps[item[id]] = item;
        });
        let item, parentItem;
        let deleteKey = [];//父子节点衔接后需要删除的temps节点
        //衔接父子节点
        for(let key in temps){
            item = temps[key];
            parentItem = temps[item[parent]];
            if(parentItem) {
                if (!parentItem.children) {
                    parentItem.children = [];
                }
                parentItem.children.push(item);
                deleteKey.push(key);
            }
        }
        //整理到tree数组中
        for(let key in temps){
            deleteKey.includes(key) ? (delete temps[item]): (tree.push(temps[key]));
        }
        //排序
        orderNo && sortTree(tree, orderNo);
        return tree;
    }
}());

//阻止移动端浏览器滚动事件
let preventBrowserScroll = (function(){
    return function(){
        let top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop,
            body = document.body;
        body.style.position = 'fixed';
        body.style.top = -top + 'px';
        body.style.left = 0;
    }
}());
let refreshBrowserScroll = (function(){
    return function () {
        let body = document.body,
            top = Math.abs(parseFloat(body.style.top));
        body.style.position = '';
        body.style.top = '';
        body.style.left = '';
        document.documentElement.scrollTop = top;
        window.scrollTo(0, top);
    }
}());

//比较对象是否一致
let compareObj = (function(){
    let isObj = (object) => {
        return object && typeof(object) == 'object';
    }
    //判断长度
    let getLength = (object) => {
        var count = 0;
        for(var i in object) count++;
        return count;
    }
    let compareObjContent = (o1, o2) => {
        for(let key in o1){
            /*if(key == 5){
                var i=0;
            }*/
            if(!o2.hasOwnProperty(key))
                return false;
            if(typeof o1[key] === 'object'){
                if(!compareObjContent(o1[key], o2[key]))
                    return false;
            }else{
                if(o1[key] !== o2[key])
                    return false;
            }
        }
        return true;
    }
    return function (o1, o2) {
        if(!isObj(o1) || !isObj(o2)) return false;
        if(getLength(o1) != getLength(o2)) return false;
        return compareObjContent(o1,o2) && compareObjContent(o2,o1);
    }
}());

//工具方法
/*let commonUtils = {
  scrollTo, //滚动效果
  getComputed //获取元素属性值
}*/

export {
    getOffset,
    scrollTo,
    getComputed,
    refreshPosition,
    validBrowser,
    promiseRequestConfig,
    promiseRequestNavConfig,
    Cookie,
    mouseWheel,
    initSpreadFunction,
    buildTree,
    preventBrowserScroll,
    refreshBrowserScroll,
    compareObj
}
